.person-profile {
  // max-width: 41.6rem;
  // width: 100%;

  .name-position {
    p {
      margin-bottom: 0.4rem;
    }
  }

  .actions {
    gap: 0.2rem;

    .icon {
      width: 1.8rem;
    }

    &__button {
      width: 5.6rem;
      height: 4.8rem;
      background: light-dark(rgba($primary, 0.06), rgba($white, 0.16));
    }
  }
}