.lightbox {
  z-index: 99;
  max-width: unset;

  &__close {
    z-index: 100;
    position: absolute;
    top: 0;
    width: 7.4rem;
    height: 7.4rem;
    right: 0;
    
    @include lg-up {
      right: 6.4rem;
    }
  }

  &__lightbox {
    height: 80vh;
    
    @include lg-up {
      height: 100vh;
    }
  }

  & .swiper-button {
    &-prev {
      opacity: 0;
      
      @include lg-up {
        opacity: 1;
        height: 100%;
        top: 0;
        left: 0;
        padding: 6.4rem;
      }

      &::after {
        color: $white;
        
      }
    }

    &-next {
      opacity: 0;
      
      @include lg-up {
        opacity: 1;
        height: 100%;
        top: 0;
        right: 0;
        padding: 6.4rem;
      }

      &::after {
        color: $white;
        
      }
    }
  }
}

#impressionLightbox {
  & img {
    object-fit: contain !important;
  }

  & .swiper-button-prev, .swiper-button-next {
    &::after {
      color: $black;
    }
  }

  & .swiper-button-disabled {
    opacity: 0;
  }
}

#plaeneLightbox {
  & img {
    object-fit: contain !important;
  }

  & .swiper-button-prev, .swiper-button-next {
    &::after {
      color: $black;
    }
  }

  & .swiper-button-disabled {
    opacity: 0;
  }
}