.newsgrid {
  &__wrapper {
    & .image {
      height: 32.5rem !important;
    }
  }

  &__news {
    & svg {
      fill: $primary;
    }

    & img {
      object-fit: cover;
    }

    &:nth-child(3n+1) {
      padding-right: 0.2rem;
    }
    
    &:nth-child(3n+2) {
      padding: 0 0.2rem;
    }

    &:nth-child(3n+3) {
      padding-left: 0.2rem;
    }
  }
}
