.projektgrid {
  &__options {
    & svg {
      fill: $primary;
    }

    &__filter {
      position: relative;

      // &-open {
      //   opacity: 1;
      //   transition: opacity 0.4s ease;
      // }

      // &-close {
      //   opacity: 0;
      //   margin-left: -1.8rem;
      //   transition: opacity 0.4s ease;
      // }

      // &-active {
      //   & .projektgrid__options__filter-open {
      //     opacity: 0;
      //   }

      //   & .projektgrid__options__filter-close {
      //     opacity: 1;
      //   }
      // }

      &-activeIndicator {
        &::after {
          content: "";
          width: 0.6rem;
          height: 0.6rem;
          background-color: #E63027;
          position: absolute;
          top: 2px;
          right: -8px;
          border-radius: 50%;
        }
      }
    }

    &__search {
      position: relative;

      &-activeIndicator {
        &::after {
          content: "";
          width: 0.6rem;
          height: 0.6rem;
          background-color: #E63027;
          position: absolute;
          top: 2px;
          right: -8px;
          border-radius: 50%;
        }
      }
    }
  }

  &__filter {
    &__categories {
      height: 0;
      overflow: hidden;
      transition: all 0.4s ease;

      &-wrapperActive {
        height: 13.6rem;

        @include lg-up {
          height: 4.6rem;
        }
      }

      &-active {
        background: #E63027;
        color: white;
      }

      & p {
        transition: all 0.4s ease;
        border-bottom: solid 0.1rem transparent;

        &:hover {
          border-bottom: solid 0.1rem $primary;
        }
      }
    }
  }

  &__searchinput {
    height: 0;
    overflow: hidden;
    transition: all 0.4s ease;

    & svg {
      fill: #00000066;
      transition: all 0.4s ease;
    }

    &:hover {
      & svg {
        fill: $primary;
      }
    }

    &-wrapperActive {
      height: 7.6rem;
    }
  }

  &__grid {
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      gap: 0.4rem;
      
      @include lg-up {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2.4rem;
      }
    }

    &__item {
      max-height: 23.6rem;
      opacity: 1;
      position: relative;
      transition: all 0.4s ease;
      width: 100%;

      & img {
        object-fit: cover;
        transition: all 0.4s ease;
      }

      &--hidden {
        max-height: 0;
        opacity: 0;
        width: 0;
      }

      &__text {
        position: absolute;
        transition: all 0.4s ease;
        bottom: -1.2rem;
        opacity: 0;
        z-index: 1;
      }

      &:hover {
        & img {
          filter: brightness(0.7);
        }

        & .projektgrid__grid__item__text {
          opacity: 1;
          bottom: 0;
        }
      }

      &--11 {
        grid-column: auto / span 1;
        grid-row: auto / span 1;
      }

      &--12 {
        max-height: 50rem;
        grid-row: auto / span 1;
        grid-row: auto / span 2;
      }
      
      &--22 {
        max-height: 50rem;
        grid-column: auto / span 2;
        grid-row: auto / span 2;
      }
      
      &--32 {
        max-height: 50rem;
        grid-column: auto / span 2;
        grid-row: auto / span 2;

        @include lg-up {
          grid-column: auto / span 3;
          grid-row: auto / span 2;
        }
      }

      &--21 {    
        grid-column: auto / span 2;
        grid-row: auto / span 2;

        @include lg-up {
          grid-column: auto / span 2;
          grid-row: auto / span 1;
        }
      }
    }
  }

  &__notfound {
    & h2 {
      font-weight: 700;
      margin-bottom: 1.2rem;
      color: rgba($color: $black, $alpha: 0.4);
    }
    
    & p {
      color: rgba($color: $black, $alpha: 0.4);
    }
  }
}