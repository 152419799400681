.teamteaser {
  &__wrapper {
    & .image {
      height: 23.4rem !important;
    }
  }

  &__teammitglied {
    @include lg-up {
      &:nth-child(3n+1) {
        padding-right: 1.6rem;
      }
      
      &:nth-child(3n+2) {
        padding: 0 1.6rem;
      }
  
      &:nth-child(3n+3) {
        padding-left: 1.6rem;
      }
    }
  }
}