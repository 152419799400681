.cursor {
  position: fixed;
  border-radius: 50%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 99999;
  opacity: 0;
  background-color: $primary;

  & p {
    font-weight: 500;
  }
  
  @include lg-up {
    width: 10rem;
    height: 10rem;
  }

  &-project {
    opacity: 0;
    z-index: -1;
    transition: all 0.4s ease;
    
    &-active {
      & .cursor-project {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  
  &-more {
    opacity: 0;
    z-index: -1;
    transition: all 0.4s ease;
    
    &-active {
      & .cursor-more {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  
  &-extern {
    opacity: 0;
    z-index: -1;
    transition: all 0.4s ease;
    
    &-active {
      & .cursor-extern {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  
  &-galery {
    opacity: 0;
    z-index: -1;
    transition: all 0.4s ease;
    
    &-active {
      & .cursor-galery {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  
  &-prev {
    opacity: 0;
    z-index: -1;
    transition: all 0.4s ease;
    
    &-active {
      & .cursor-prev {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  
  &-next {
    opacity: 0;
    z-index: -1;
    transition: all 0.4s ease;
    
    &-active {
      & .cursor-next {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  
  &-close {
    opacity: 0;
    z-index: -1;
    transition: all 0.4s ease;
    
    &-active {
      & .cursor-close {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  
  &-back {
    opacity: 0;
    z-index: -1;
    transition: all 0.4s ease;
    
    &-active {
      & .cursor-back {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  
  &-mail {
    opacity: 0;
    z-index: -1;
    transition: all 0.4s ease;
    
    &-active {
      & .cursor-mail {
        opacity: 1;
        z-index: 1;
      }
    }
  }
}

.microinteractions {
  &__toast {
    padding: 1.2rem;
    border-radius: 0.5rem;
    position: fixed;
    left: 50%;
    bottom: -4.4rem;
    transform: translateX(-50%);
    color: $white;
    background-color: #DD2E25;
    text-align: center;

    &-visible {
      animation: toastAnim 3s cubic-bezier(0.26,-0.47, 0.48, 1.53);
    }
  }

  &__readprogress {
    height: 0.3rem;
    top: 0;
    left: 0;
    width: 0;
    position: fixed;
    background-color: $primary;
  }

  &__back {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 2.4rem;
    
    &:hover {

      &::after {
        opacity: 1;
      }
    }
    
    &::after {
      content: '';
      width: 2.4rem;
      height: 100%;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.13) 100%);
      opacity: 0;
      transition: all 0.4s ease;
      display: block;

      @include lg-up {
        width: 4.8rem;
      }
    }
  }
}

@keyframes toastAnim {
  0% {
    bottom: 0rem;
  } 
  10% {
    bottom: 2.4rem;
    padding: 1.2rem 2.4rem;
  }
  90% {
    bottom: 2.4rem;
    padding: 1.2rem 2.4rem;
  }
  100% {
    bottom: -4.4rem;
    padding: 1.2rem;
  }
}