.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

// Background colors
.bg-primary {
  background-color: light-dark($primary, $primary-dark) !important;
}

.bg-secondary {
  background-color: light-dark($secondary, $secondary-dark) !important;
}

.bg-white {
  background-color: light-dark($white, $black) !important;
}

.bg-black {
  background-color: light-dark($black, $white) !important;
}

.bg-gray-100 {
  background-color: light-dark($gray-100, $gray-800) !important;
}

.bg-gray-200 {
  background-color: light-dark($gray-200, $gray-800) !important;
}

.bg-gray-300 {
  background-color: light-dark($gray-300, $gray-700) !important;
}

.bg-gray-400 {
  background-color: light-dark($gray-400, $gray-600) !important;
}

// Text colors
.text-primary {
  color: light-dark($primary, $text-primary-dark) !important;
}
.text-secondary {
  color: light-dark($secondary, $text-secondary-dark) !important;
}

.text-white {
  color: light-dark($white, $black) !important;

  & > svg {
    fill: light-dark($white, $black) !important;
  }
}

.text-black{
  color: light-dark($black, $white) !important;

  & > svg {
    fill: light-dark($black, $white) !important;
  }
}

.link-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

// cursort pointer
.c-p {
  cursor: pointer;
}

// flex positioning

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-center-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mb-05 {
  margin-bottom: 0.4rem;
}

.title-deco {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -12px; /* Adjust to control distance below the text */
    width: 64px;
    height: 8px;
    background-color: $primary; /* Red color */
  }
}

.animate {
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition: opacity 0.5s ease, transform 0.4s ease;
}

.js-scrollinto {
  opacity: 0;
  transform: translateY(3rem);
}