.input {
  &-wrapper {
    position: relative;

    &.light {
      input,
      input:focus {
        color: light-dark($white, $cta-selected);
      }
      .input-field {
        border-bottom: 1px solid rgba($white, 0.32);

        & + label {
          color: rgba($white, 0.32);
        }
      }

      &:hover {
        .input-field {
          border-bottom: 1px solid $white;

          & + label {
            color: $white;
          }
        }
      }
    }

    &:hover {
      .input-field {
        border-bottom: 1px solid light-dark(rgba($primary, 1), rgba($text-primary-dark, 1));

        & + label {
          color: light-dark(rgba($primary, 1), rgba($text-primary-dark, 1));
        }
      }
    }

    textarea {
      &.input-field {
        padding: 0;
        margin: 4rem 0;
        height: 5rem;
        min-width: 100%;
        max-width: 100%;
        max-height: 20rem;
        padding:  0 0 2rem 0 !important;
        font-family: 'Montserrat', sans-serif;
        overflow: hidden;
        transition: all 0.4s ease;
        
        & + label {
          position:absolute;
          top:0;
          left:0;
          margin-top: 2rem;
          font-size: 1.8rem;
          pointer-events:none;
          transition:0.5s;
          color: rgba($black, 0.4);
        }

        &:not(:placeholder-shown) {
          height: 20rem;
        }

        &:not(:placeholder-shown) + label {
          top: -0.9rem !important;
        }
      }
    }
    
    .input-field {
      width:100%;
      padding: 4rem 0 2rem 0;
      border:none;
      outline:none;
      border-bottom: 1px solid $cta-selected;
      background: transparent;
      font-size: 1.8rem;
      font-family: 'Montserrat', sans-serif;

      &:focus {
        color: light-dark($black, $text-primary-dark);
      }

      & + label {
          position:absolute;
          top:0;
          left:0;
          margin-top: 3.8rem;
          font-size: 1.8rem;
          pointer-events:none;
          transition:0.5s;
          color: rgba($black, 0.4);
      }

      &:not([type="radio"]):not(:placeholder-shown) + label, 
      select:not(:placeholder-shown) + label, 
      textarea:not(:placeholder-shown) + label {
        position: absolute;
        top: -2.9rem;
        font-size: 1.4rem;
        -webkit-transition: 0.15s all ease;
        transition: 0.15s all ease;
      }
    }

    .disabled {
      pointer-events: none;
    }

    .form-attachment {
      opacity: 0;
      padding: 2rem 0;
      cursor: pointer;

      &:hover {
        & + .attachment-placeholder .icon {
          color: light-dark($black, $text-primary-dark);
        }
      }
    }

    .attachment-placeholder {
      top: 50%;
      transform: translateY(-50%);
      padding: 2rem 0;
      border-bottom: 0.1rem solid light-dark($black, $text-primary-dark);
      color: light-dark(rgba($black, 0.32), $text-primary-dark);

      .clear-attachment {
        cursor: pointer;
      }

      .icon {
        color: light-dark(rgba($black, 0.16), $text-primary-dark);
        width: 1.4rem;
        height: 1.4rem;

        &:hover {
          color: light-dark($black, 0.16, $cta-hover-dark);
        }
      }
    }

    select {
      width: 100%;
      padding: 2rem 0;
      border: none;
      border-bottom: 1px solid;
      color: $black;
      -webkit-appearance: menulist-button;

      &:invalid {
        color: light-dark(rgba($black, 0.32), $text-primary-dark);
      }
    }

    &.half {
      display: inline-block;
      width: 50%;

      // @include md-up {
      //   width: calc(50% - 2.4rem);

      //   &:nth-child(even) {
      //     margin-left: 3.6rem;
      //   }
      // }
    }

  }

  &-search {
    .input-field + label {
      left: 4.8rem;
    }

    .input-field:placeholder-shown ~ button{
        opacity: 0;
        pointer-events: none;
    }

    .search-close-icon {
      right: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;

      svg {
        color: light-dark(rgba($dark, 0.16), $gray-100);
      }

      &:hover svg {
        color: light-dark($black, $cta-hover-dark);
      }
    }
    .search-icon {
      top: 50%;
      transform: translateY(-50%);
      color: light-dark(rgba($dark, 0.16), $gray-100) ;
    }
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}

.select {
  position: relative;

  &__dropdown {
    position: relative;
    color: light-dark($primary, $text-primary-dark);
  
    &--container {
      position: absolute;
      z-index: 13;
      background: light-dark($white, transparent) ;
      top: 100%;
      width: 100%;
      box-shadow: 0 0.2rem 0.3rem $gray-300;
    }
  }

  &__radio__wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  
    &:hover {
      color: light-dark($primary, $cta-hover-dark);
    }

    &.active {
      color: light-dark($primary, $cta-hover-dark);
    }
  
    label {
      position: relative;
      width: 100%;
      font-size: 1.6rem;
      margin-bottom: 0;
      padding: 0.8rem;
      cursor: pointer;
    }
  
  
    input[type='radio'] {
      height: 0;
      line-height: 0;
      padding: 0;
      position: absolute;
      opacity: 0;
      margin: 0 0.5rem 0 0;
      filter: grayscale(1);
  
      &:checked {
        ~ .checkmark {
          background: light-dark($black, $white);
  
          &:after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='12px' viewBox='0 0 18 14'%3E%3Cg id='Icon/Content/check' transform='translate(-3.000000, -5.000000)' fill='%23fff'%3E%3Cpolygon id='Shape' points='9 16.2 4.8 12 3.4 13.4 9 19 21 7 19.6 5.6'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

.dropdown__main {
  cursor: pointer;

  .input-field {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid light-dark($black, $text-primary-dark);
    padding: 2rem 0;
    width: 100%;
    color: light-dark($primary, $text-primary-dark);
  }

  label {
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    &.selected {
      top: -0.6rem;
      font-size: 1rem;
      transform: none;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    width: 1.2rem;
    color: light-dark(rgba($black, 0.32), $text-primary-dark);
  }

  &:hover {
    svg {
      color: light-dark($black, $cta-hover-dark);
    }
  }

  &.active {
    svg {
      transform: rotate(-180deg);
      top: 25%;
    }
  }

  input {
    cursor: pointer;
  }
}

.button-group {
  & .mr-1 {
    margin-right: 0.8rem;
  }
  
  &__title {
    color: rgba($black, 0.4);
    font-size: 1.4rem;
  }
  
  &__button {
    position: relative;
    
    label {
      border-bottom: 0.2rem solid transparent;
      cursor: pointer;
      color: $black;
      font-weight: 500;
      transition: all 0.4s ease;

      &:hover {
        border-bottom-color: $primary;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      pointer-events: none;
  
      &:checked {
        + label {
          border-bottom-color: $primary;
        }
      }
    } 
  }
}

.radio__wrapper {
  gap: 0.8rem;
  display: flex;
  align-items: center;

  input[type="radio"],
  input[type="checkbox"] {
    -webkit-appearance: none;
    display: inline-grid;
    place-content: center;
    appearance: none;
    background-color: $white;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.6rem;
    height: 1.6rem;
    border: 0.2rem solid $black;
    border-radius: 50%;
    transform: translateY(-0.075em);

    &::before {
        content: "";
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        transform: scale(0);
        transition: 0.1s transform ease-in-out;
        box-shadow: inset 1em 1em $radio-checked;
    }
    &:checked {
      border: 0.15em solid $radio-checked;

      &::before {
        transform: scale(1);
      }
    }

    &:disabled {
      border: 0.15em solid $gray-300;

      & ~ label {
        color: $gray-300;
      }
    }

  }

  input[type="checkbox"] {
    border-radius: 0rem;
    border: 0.1rem solid $black;

    &:before {
      border-radius: 0;
      width: 1.8rem;
      height: 1.8rem;
      box-shadow: none;
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.3335 0H2.3335C1.2235 0 0.333496 0.9 0.333496 2V16C0.333496 17.1 1.2235 18 2.3335 18H16.3335C17.4435 18 18.3335 17.1 18.3335 16V2C18.3335 0.9 17.4435 0 16.3335 0ZM7.3335 14L2.3335 9L3.7435 7.59L7.3335 11.17L14.9235 3.58L16.3335 5L7.3335 14Z' fill='%23E63027'/%3E%3C/svg%3E%0A");
    }
    
  }

  .form-control + .form-control {
    margin-top: 1em;
  }
}

.range {
  &-wrapper {
    position: relative;
    height: 6rem;
    z-index: 1;

    label {
      cursor: pointer;
    }

    // &.light {
    //   input,
    //   input:focus {
    //     color: $white;
    //   }
    //   .input-field {
    //     border-bottom: 1px solid rgba($white, 0.32);

    //     & + label {
    //       color: rgba($white, 0.32);
    //     }
    //   }

    //   &:hover {
    //     .input-field {
    //       border-bottom: 1px solid $white;

    //       & + label {
    //         color: $white;
    //       }
    //     }
    //   }
    // }

    .range-input-double {
      position: absolute;
      box-sizing: border-box;
      appearance: none;
      border: 0;
      outline: none;
      pointer-events: none;
      z-index: -1;

      &:nth-child(2) {
        background: none !important;
      }
    }
    
    .range-input {
      width:100%;
      margin: 3.6rem 0;
      height: 0.4rem;
      border:none;
      background: light-dark($black, $white);
      -webkit-appearance: none;
      background-image: linear-gradient($gray-600, $gray-600);
      background-size: 0% 100%;
      background-repeat: no-repeat;

      &::-webkit-slider-thumb {
        appearance: none;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        margin-top: -0.7rem;
        background: light-dark($primary, $cta-selected-dark);
        cursor: pointer;
        pointer-events: all;
      }

      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 0.4rem;
        margin-top: -1.9rem;
        cursor: pointer;
        border: none;
        position: absolute;
        z-index: -1;
      }
    }

    .range-label {
      position:absolute;
      top:0;
      left:0;
    }

    .range-value {
      position:absolute;
      top:0;
      right:0;
      font-size: 1.8rem;
    }

    .disabled {
    }

    &.half {
      display: inline-block;
      width: 100%;

      @include md-up {
        width: calc(50% - 2.4rem);

        &:nth-child(even) {
          margin-left: 3.6rem;
        }
      }
    }
  }

  &-markers {
    display: flex;
    justify-content: space-between;
    margin-top: -4.3rem;

    &-double {
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      top: 3.3rem;
      z-index: -5;
    }
    
    &-marker {
      &::before {
        content: '';
        display: block;
        background-color: light-dark($gray-800, $cta-selected-dark);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
      }
    }
  }
}