.btn {
  position: relative;
  padding: 1.4rem 2.4rem;
  transition: all 0.5s ease;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  min-width: fit-content;
  max-width: fit-content;
  font-size: 2.4rem;
  font-weight: 300;

  &--default {
    background-color: transparent;
    border: 0.1rem solid;
    border-color: $primary;
    color: $primary;

    &:hover {
      color: $white;
      background-color: $primary;
    }
  
    &:active {
      color: $white;
      background-color: $primary;
    }
  }

  &.right {
    flex-direction: row-reverse;
  }

  &--apply {
    color: $black;
    position: relative;
    background-color: transparent;
    font-size: 2rem;

    & svg {
      transition: all 0.4s ease;
      fill: $primary;
    }

    &:hover {
      & svg {
        transform: scale(1.05);
      }
    }
  }

  &--submit {
    color: $black;
    position: relative;
    background-color: transparent;

    & svg {
      transition: all 0.4s ease;
      fill: $primary;
    }

    &:hover {
      & svg {
        transform: scale(1.05);
      }
    }
  }

  &--inline {
    color: $black;
    position: relative;
    background-color: transparent;

    &-external {
      & svg {
        transform: rotate(-45deg) !important;
      }
    }

    &:hover {
      & svg {
        transform: translateX(1rem); 
      }
    }

    & svg {
      transition: all 0.4s ease;
      fill: $primary;
    }
  }

  &--back {
    color: $black;
    font-size: 2.4rem !important;

    &:hover {
      & svg {
        transform: translateX(-1rem); 
      }
    }

    & svg {
      fill: #666666;
      transition: all 0.4s ease;
    }
  }
}