.newsteaser {
  & svg {
    fill: $primary;
  }

  & img {
    object-fit: cover;
  }

  & .swiper {
    padding-bottom: 6rem;
    
    @include lg-up {
      padding-bottom: 8rem;
    }

    &-button {
      &-prev {
        top: unset;
        bottom: 0;
        left: 35%;
        
        @include lg-up {
          left: 45%;
        }

        &::after {
          font-size: 3rem;
          color: $primary;
        }
      }
      
      &-next {
        top: unset;
        bottom: 0;
        right: 35%;
        
        @include lg-up {
          right: 45%;
        }

        &::after {
          font-size: 3rem;
          color: $primary;
        }
      }
    }

    &-pagination {
      bottom: unset;
      left: unset;
      top: 1.2rem;
      right: 2.4rem;
      width: unset;
      
      @include lg-up {
        right: 6.4rem;
      }

      &-bullet {
        width: 3.2rem;
        height: 0.4rem;
        border-radius: 0;
        opacity: 1;
        margin: 0.2rem !important;
        background-color: $gray-100;
        
        &-active {
          height: 0.4rem;
          background-color: $primary;
        }
      }
    }
  }
}