@import '_config/mixins';
@import '~bootstrap/scss/functions';
@import '_config/variables';
@import '_config/colorVariables';
@import '_config/fontVariables';

@import '_config/fonts';
@import '_config/utilities';
@import 'bootstrap/scss/bootstrap-grid.scss';
@import 'bootstrap/dist/css/bootstrap-utilities.min.css';
@import '_config/settings';
@import '_config/cookiebot';

//NPM packages
@import 'swiper/swiper-bundle';


@import '01-atoms/typography/typography';
@import '01-atoms/button/button';
@import '01-atoms/icons/icon';
@import '01-atoms/navigation/navigation';
@import '01-atoms/readmore/readmore';
@import '01-atoms/inputs/inputs';
@import '01-atoms/galeryItem/galeryItem';
@import '01-atoms/personProfile/personProfile';
@import '01-atoms/sectionTitle/section-title';
@import '01-atoms/image/image';

// @import '02-molecules/accordion/accordion';
// @import '02-molecules/alerts/alerts';
@import '02-molecules/hero/hero';
// @import '02-molecules/popup/popup';
// @import '02-molecules/iconList/iconList';
@import '02-molecules/multitext/multitext';
@import '02-molecules/slider/slider';
@import '02-molecules/lead/lead';
// @import '02-molecules/search/search';
@import '02-molecules/contactBlock/contactBlock';
@import '02-molecules/testimonials/testimonials';
@import '02-molecules/newsteaser/newsteaser';
@import '02-molecules/threeTeaser/threeTeaser';
// @import '02-molecules/pageTitle/pageTitle';
@import '02-molecules/contactInfo/contactInfo';
@import '02-molecules/offenestellen/offenestellen';
@import '02-molecules/filter/filter';
@import '02-molecules/newsgrid/newsgrid';
@import '02-molecules/content/content';
@import '02-molecules/dialogues/dialogues';
@import '02-molecules/notFound/notFound';
@import '02-molecules/projektteaser/projektteaser';
@import '02-molecules/projektgrid/projektgrid';
@import '02-molecules/projektdetail/projektdetail';
@import '02-molecules/twoColumn/twoColumn';
@import '02-molecules/figures/figures';
@import '02-molecules/teamteaser/teamteaser';
@import '02-molecules/teamgrid/teamgrid';
@import '02-molecules/lightbox/lightbox';
@import '02-molecules/newsarticle/newsarticle';

@import '03-organisms/header/header';
@import '03-organisms/footer/footer';
@import '03-organisms/loginpage/loginpage';
@import '03-organisms/globalanimations/cursor';
