.hero {
  max-width: unset;

  & .swiper {
    height: 80vh;

    @include lg-up {
      height: 100vh;
    }
  }

  &__background {
    & .image {
      max-width: unset;
    }
    
    &__image {
      object-fit: cover;

      @include xl-up {
        height: 100vh;
      }
    }
  }

  &__lead p {
    font-size: 2rem;

    @include lg-up {
      font-size: 2.4rem;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 2rem;
    
    &__small {
      font-weight: 700;
      font-size: 2.4rem;
    }

    &__wrapper {
      position: absolute;
      bottom: 3.4rem;
      transform: translateY(13rem);
      animation: textAnim 800ms ease forwards;

      @keyframes textAnim {
        0% {
          transform: translateY(13rem);
        }
        100% {
          transform: translateY(0);
        }
      }
      
      @include lg-up {
        left: 6.4rem;
      }
    }
  }

  &__anchor {
    // color: #00000033;
    transition: all 0.4s ease;

    & h2 {
      font-size: 3.2rem !important;
    }

    @include lg-up {
      & h2 {
        font-size: 4.8rem !important;
      }
    }
  }

  & .swiper {
    & .swiper-button {
      &-prev {
        top: 2.4rem;
        height: 100%;
        width: 33%;
        left: 0;
        transition: all 0.4s ease;
        
        @include lg-up {
          top: 0;
          opacity: 0;
        }
        
        &::after {
          color: $white;
          position: absolute;
          left: 2.4rem;

          @include lg-up {
            left: 6.4rem;
          }
        }
      }

      &-next {
        top: 2.4rem;
        height: 100%;
        width: 33%;
        right: 0;
        transition: all 0.4s ease;

        @include lg-up {
          top: 0;
          opacity: 0;
        }

        // &:hover {
        //   background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.3) 100%);
        // }

        &::after {
          color: $white;
          position: absolute;
          right: 2.4rem;
          
          @include lg-up {
            right: 6.4rem;
          }
        }
      }
    }
  }
}