.footer {
  * {
    color: $black;
  }

  svg {
    fill: $black;
  }

  & a {
    font-weight: 300;
  }

  &__address {
    p {
      margin: 0;
      font-size: 1.6rem;
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  &__language {
    &-link {
      
      &:not(:last-child):after {
        content: " | ";
        padding: 0 10px;
        color: $white;
      }
    }
  }
}