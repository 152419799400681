.contactform {
  &__inner {
    transition: all 0.4s ease;
    max-height: 80rem;
    position: relative;

    &--spinner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      user-select: none;
      z-index: 1;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 35%, rgba(255, 255, 255, 0.6) 69%, rgba(255, 255, 255, 0) 290%);

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px; /* Size of the spinner */
        height: 50px;
        border: 5px solid $primary; /* Light gray border for the background */
        border-top-color: $white; /* Blue border for the spinner animation */
        border-radius: 50%; /* Make it circular */
        animation: spin 1.5s ease infinite; /* Spin animation */
        transform: rotate(25deg);
      }
    }
    @keyframes spin {
      0% {
        transform: rotate(25deg);
      }
      100% {
        transform: rotate(385deg);
      }
    }

    &__hidden {
      opacity: 0 !important;
      z-index: -1 !important;
      user-select: none !important;
      pointer-events: none !important;
      max-height: 0 !important;
    }
  }

  &__phone {
    &__number {
      font-size: 2.4rem;
    }
  
    &__email {
      font-size: 2.4rem;
    }

    &__officehours {
      font-size: 1.8rem;
    }
  }
}

.form {
  &__success {
    transition: all 0.4s ease;
    max-height: 20rem;
  }

  &__msg {
    &-hidden {
      opacity: 0;
      z-index: -1;
      user-select: none;
      pointer-events: none;
      max-height: 0rem !important;
    }
  }
  
  &__error {
    max-height: 20rem;
    transition: all 0.4s ease;
  }
}