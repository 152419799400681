.projektdetail {
  &__image {
    overflow: hidden;

    & .image {
      max-width: unset;
    }
  }

  &__options {
    &__items {
      font-weight: 500;
      transition: all 0.4s ease;
      border-bottom: solid 0.1rem transparent;

      &:hover {
        border-bottom: solid 0.1rem $primary;
      }

      &-active {
        border-bottom: solid 0.1rem $primary;
      }
    }
  }

  &__galery {
    &-active {
      opacity: 1;
    }
    
    &-inactive {
      height: 0;
      opacity: 0;
    }

    &__hidden {
      opacity: 0 !important;
      height: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &__impressions {
    transition: opacity 0.4s ease;
    overflow: hidden;

    &__item {
      width: 100%;
      height: 48rem;
      padding-bottom: 3.2rem;
      transition: all 0.4s ease;
      
      &--automaticGrid {
        @include xl-up {
          width: calc(50% - 1.6rem);
  
          &:nth-child(10n+4) {
            height: 80rem;
          }
        }
      }

      &--manualGrid {
        @include xl-up {
          &--100 {
            width: 100%;
            height: 80rem;
          }
  
          &--50 {
            width: calc(50% - 1.6rem);
          }
          
          &--25 {
            width: calc(25% - 2.4rem);
          }
          
          &--hochformat {
            height: 80rem;
            width: calc(50% - 1.6rem);
          }
        }
      }

      &__inactive {
        height: 0;
        overflow: hidden;
        opacity: 0;
      }
    }
  }

  &__plaene {
    transition: opacity 0.4s ease;
    overflow: hidden;

    &__item {
      width: 100%;
      transition: all 0.4s ease;
      
      @include lg-up {
        width: 49%;
      }
    }
  }

  &__morebuttons {
    & svg {
      fill: $primary;
    }

    &__showmore {
      font-size: 1.6rem;

      &-more {
        display: block;
      }

      // &-less {
      //   display: none;
      // }
    }
  }

  &__stats {
    &__item {
      border-bottom: #0000001A solid 0.1rem;
      width: 100%;

      @include lg-up {
        width: 48%;
      }
    }
  }

  &__navigation {
    &__back {
      &:hover svg {
        transform: translateX(-1rem); 
      }
    }
    
    &__forward {
      &:hover svg {
        transform: translateX(1rem); 
      }
    }

    & svg {
      fill: $primary;
      transition: all 0.4s ease;
    }
  }
}