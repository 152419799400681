.offenestellen {
  &__jobs {
    &__item {
      // border-left: 0.3rem solid $primary;

      &:hover {
        & svg {
          transform: translateX(0.5rem);
        }
      }

      & svg {
        transition: all 0.4s ease;
        fill: $primary;
      }
    }
  }
}