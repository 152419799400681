.loginpage {
  &__inner {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 23.9rem;
    display: flex;
    top: 4rem;
    position: absolute;
    height: 2.6rem;
    padding-left: 2.4rem;

    @include lg-up {
      padding-left: 6.4rem;
    }

    & svg {
      width: 100%;
      height: 100%;
    }
  }

  & input[type=password]:not(:placeholder-shown) {
    & ~ ul {
      opacity: 0;
    }
  }

  &__form {
    width: 87%;

    & label {
      color: $black;
      position: absolute;
      top: 2rem;
      transition: all 0.4s ease;
      cursor: text;
      user-select: none;
    }

    &__input {
      display: flex;
      position: relative;

      & input {
        border: 0;
        border-bottom: 0.2rem solid $black !important;
        padding: 2rem 0;
        width: 28rem;

        &:not(:placeholder-shown) + label {
          top: -0.9rem !important;
        }

        &:focus {
          outline: none !important;
          box-shadow: 0 0 0 0 #ffffff00 !important;
          
          & ~ ul {
            opacity: 0;
          }
        }
      }

      & button {
        padding: 0;
        background: 0;
        border: 0;
        transition: all 0.5s ease;
        cursor: pointer;
        
        & svg {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }
}

.errors {
  padding: 0;
  margin: 0;
  position: absolute;
  opacity: 1;
  transition: all 0.5s ease;
  top: 6.8rem;
  pointer-events: none;

  & li {
    list-style: none;
    color: $primary;
  }
}