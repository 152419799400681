.projektteaser {
  &__grid {
    &__container {
      display: grid;
      justify-content: space-between;
      gap: 0.4rem;
      grid-template-columns: 1fr 1fr;
      
      @include lg-up {
        gap: 2.4rem;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &__item {
      position: relative;
      transition: all 0.4s ease;
      min-height: 8.8rem;
      
      @include lg-up {
        max-height: 23.6rem;
      }

      & img {
        object-fit: cover;
        transition: all 0.4s ease;
      }
      
      &__text {
        position: absolute;
        transition: all 0.4s ease;
        bottom: -1.2rem;
        opacity: 0;
        z-index: 1;
        display: none;

        @include lg-up {
          display: block;
        }
      }

      &:hover {
        & img {
          filter: brightness(0.7);
        }

        & .projektteaser__grid__item__text {
          opacity: 1;
          bottom: 0;
        }
      }

      &--12 {
        grid-row: auto / span 2;
        max-height: 50rem;
      }
      
      &--22 {
        grid-column: auto / span 2;
        grid-row: auto / span 2;
        max-height: 50rem;
      }

      &:nth-child(1) {
        transform: unset !important;
        grid-row: auto / span 2;
        grid-column: auto / span 2;
        
        @include lg-up {
          grid-column: auto / span 1;
        }

        &::after {
          display: none;
        }
      }

      &:last-child {
        display: none;

        @include lg-up {
          display: flex;
        }
      }
    }
  }
}