$hamburgerWidth: 36rem;

.header {
  z-index: 99;
  width: 100%;
  top: 0;

  &__inner {
    padding: 1.2rem 0;
  }

  &__nav nav {
    transition: all 0.4s ease;
  }

  &__logo {
    z-index: 9;
    transition: all 0.4s ease;
    margin-top: 1.2rem;
      
    &__full {
      & path {
        opacity: 0;
        transition: opacity 0.4s ease, transform 0.2s ease 0.4s;
        transform: translateX(-0.2rem);
      }
      
      &-a {
        transition: transform 0.4s ease 0.4s;
        opacity: 1;
      }
    }
  }

  $height-icon: 1.1rem;
  $width-line: 1.8rem;
  $height-line: 0.2rem;

  $rotation: 45deg;
  $translateY: calc($height-icon / 2);
  $translateX: 0;

  #hamburger-icon {
    width: $width-line;
    height: $height-icon;

    .line {
      width: $width-line;
      height: $height-line;
      left: 0;
      border-radius: calc($height-line / 2);
      transition: all 0.3s;
      
      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 50%;
      }
      &.line-3 {
        top: 100%;
      }
    }
    &:hover, &:focus {
      .line-1 {
        transform: translateY(calc($height-line / 2 * -1));
      }
      .line-3 {
        transform: translateY(calc($height-line / 2));
      }  
    }
    &.active {
      .line-1 {
        transform: translateY($translateY) translateX($translateX) rotate($rotation);
        background-color: $primary !important;
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        transform: translateY(calc($translateY * -1)) translateX($translateX) rotate(calc($rotation * -1));
        background-color: $primary !important;
      }
    }
  }

  .hamburger {
    z-index: 1;
  }

  .hamburger-menu {
    top: 0;
    left: 0;
    transform: translateX(125%);
    width: 150%;
    transition: 0.8s all ease;
    height: 100vh;
    background: rgba(246, 244, 239, 0.8980392157);
    background: linear-gradient(90deg, rgba(246, 244, 239, 0.3) 0%, rgba(246, 244, 239, 0.9) 19%);
    padding-top: 10rem !important;
    
    @include lg-up {
      padding-top: 18rem !important;
    }

    &.active {
      transform: translateX(0) !important;
    }

    &__socials {
      bottom: 6.4rem;
      right: 2.4rem;
      
      @include lg-up {
        right: 6.4rem;
      }

      & svg {
        margin: 0 !important;
        padding: 0 !important;
        fill: $primary;
      }
    }

    a {
      svg {
        width: 1.6rem;
        height: 1.6rem;
        padding: 0.3rem;
        margin-left: 1rem;
      }
    }

    &__item {
      font-size: 4.8rem;
      font-weight: 300;

      & a {
        color: $black;
        position: relative;
      }

      &.has-children {
        .hamburger-menu__submenu {
          top: 0;
          width: $hamburgerWidth;
          transform: translate(calc(#{$hamburgerWidth} - 3.2rem), 0);
          height: 100%;
          background: light-dark($white, $gray-800);
          color: $black;
        }
      }
    }
  }

  &.active {
    & .header__logo {
      border-color: transparent;
    }
    
    .hamburger-menu {
      transform: translateX(-33%);
      transition: 0.8s all ease;
    }
  }
}

.activeHeader {
  margin-top: unset;
  padding: 0 !important;
  background-color: transparent;

  & #hamburger-icon {
    .line {
      background-color: $black !important;
    }
  }
}