.newsarticle {
  &__content {
    & figure {
      width: 100%;
      margin: 0;
    }

    & img {
      width: 100%;
      height: unset;
      aspect-ratio: unset !important;
      object-fit: contain;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 4.8rem;
    margin-top: -4.8rem;

    &:after {
      font-size: 4rem;
      padding: 2rem;
      color: $white;
    }
  }
}