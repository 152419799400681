.dialogues {
  max-width: 100%;
  opacity: 0;
  filter: drop-shadow(0px 2px 5px light-dark(#00000040, #ffffff60));
  animation: fadeIn 0.8s ease forwards;

  @include lg-up {
    max-width: 36rem;
  }
  
  &__date {
    &__month {
      font-size: 1.4rem;
    }

    &--day {
      font-size: 2.8rem;
    }
  }
}

// .fadeIn {
//   animation: fadeIn 0.8s ease forwards;
// }

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}