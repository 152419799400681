.notfound {
  &__404 {
    font-size: 15rem;
    height: fit-content;
    
    @include lg-up {
      font-size: 36rem;
    }

    &__block {
      width: 2rem;
      height: 1rem;
      background-color: $primary;
      position: absolute;
    }

    &--deco {
      position: absolute;
      bottom: 0;
      width: 100px;
      height: 13px;
      left: 0;
      background-color: $primary; /* Red color */
      
      @include lg-up {
        bottom: 35px; /* Adjust to control distance below the text */
        width: 200px;
        height: 18px;
      }
    }

    &--deco1 {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $primary; /* Red color */
    }
  }

  &__fullheight {
    height: 100vh;
  }
}